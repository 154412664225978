import React from "react"
import { Link } from "../components/UI/Common"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"
import * as style from "./paymentSuccess.module.scss"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"
import { BRANCH_DETAILS } from "../utils/storeLocationHelper"
import {createDisplayPrice} from "../utils/productsHelper";


const PaymentSuccess = ({
  location
}) => {
  let firstName = "",
      lastName = "",
      addressLine1 = "",
      addressLine2 = "",
      city = "",
      postcode = "",
      email = "",
      deliveryMethod = "",
      pickupLocation = "",
      total = 0,
      stripePaymentSecret = "",
      orderDate = "",
      bikeAssembly = false,
      subtotal = 0,
      orderNumber = "",
      basketTotalWithDiscount=0,
      extraFee=null
  if (typeof window !== "undefined" && location.state) {
    const { additionalServices } = location.state
    if (additionalServices) {
      bikeAssembly = true
    }
    if (location.state.result) {
      extraFee = location.state.result.extraFees !== null ? location.state.result.extraFees : null
      basketTotalWithDiscount = location.state.result.basketTotalWithDiscount !== false ? location.state.result.basketTotalWithDiscount : total
      subtotal = location.state.result.subtotal.toFixed(2)

      // add bike assembly calc for CeB?
      orderNumber = location.state.result.orderId
      stripePaymentSecret = location.state.result.stripePaymentSecret
      orderDate = new Date(location.state.result.orderDate).toLocaleDateString('eng-GB', {year: 'numeric', month: 'long', day: 'numeric' })
    }
    if (location.state.basketDeliveryOptions) {
      const basketDeliveryOptions = location.state.basketDeliveryOptions
      firstName = basketDeliveryOptions.firstName
      lastName = basketDeliveryOptions.lastName
      addressLine1 = basketDeliveryOptions.addressLine1
      addressLine2 = basketDeliveryOptions.addressLine2
      city = basketDeliveryOptions.city
      postcode = basketDeliveryOptions.postcode
      email = basketDeliveryOptions.email
      deliveryMethod = basketDeliveryOptions.deliveryMethod
      pickupLocation = basketDeliveryOptions.pickupLocation
    }
    if (location.state.basketItems) {
      dataLayerUpdate('purchase', {'items': location.state.basketItems, 'value':total.toFixed(2), 'transaction_id': orderNumber})
    }
  }

  const renderCollectionInfo = () => {
    return (
      <>
        <h2>Store Collection</h2>
        {BRANCH_DETAILS.map(branch => {
          if (pickupLocation === branch.branchName) {
            return (
            <>
            <h3>Address</h3>
            <address>
            {branch.brancheName}<br />
            {branch.street}<br />
            {branch.city}<br />
            {branch.postcode}<br />
            </address>
            <h3>Telephone</h3>
            <address>
                {branch.phone}
            </address>
            <h3>Opening Hours</h3>
            <div>
            {branch.openingHours.map((item, i) =>
            <span key={i}>{item.dayOfTheWeek}: {item.hours}<br /></span>
            )}
            </div>
            </>
            )
          }
          return null
        })}
      </>
    )
  }
  const renderDeliveryInfo = () => {
    return (
      <>
        <h2>Delivery</h2>
        <h3>Delivery address</h3>
        <address>
            {firstName} {lastName}<br />
            {addressLine1}<br />
            {addressLine2 && <>{addressLine2}<br /></>}
            {city}<br />
            {postcode}<br />
        </address>
        <h3 className="mt-3">Estimated arrival date</h3>
        {/* TODO
        <h3 className="mt-3">
          Tracking Number
          <StaticImage className={style.deliveryIcon} src={dhl} />
        </h3> */}
      </>
    )
  }
  let allFees=0;
  if(extraFee !== null) {
          extraFee.length > 1
          ? allFees = (extraFee.reduce((a,b) => a.feeValue + b.feeValue))
          : allFees = extraFee[0].feeValue
  }
  let couponVal;

  if(location.state && location.state.result && location.state.result.couponValue) {
    couponVal = `${location.state.result.couponValue}%`
  }
  const isShippingEnabled = Boolean(Number(process.env.ENABLE_SHIPPING_FEE))
  let shippingFeeValue = 'Free';

  if(typeof window !== "undefined" && location.state && location.state.shippingFee !== null) {
    const convertedShippingFee = Number(location.state.shippingFee.substring(1))
    if( isShippingEnabled === false ){
        shippingFeeValue = 'Free'
      }else if(convertedShippingFee > 0 && isShippingEnabled) {
        shippingFeeValue = createDisplayPrice(String(convertedShippingFee), "paymentSuccess  Delivery")
      }
    }
    let totalValue = allFees > 0 ? createDisplayPrice(basketTotalWithDiscount+allFees,"paymentSuccess") : createDisplayPrice(basketTotalWithDiscount,"paymentSuccess")

  return (
    <Layout
      staticSeo={{
        seoTitle: "payment success",
        seoDescription: "payment success",
      }}
      location={{ pathname: "page-not-found" }}>
      <div className={style.pageBody}>
        <Container className="bg-white">
          <Row className={`d-flex justify-content-center pt-large`}>
            <Col lg={10} xl={8} className="d-flex flex-column">
              <h1 className="text-uppercase text-center">
                Thank you for your order!
              </h1>
              <p className="text-center p-3">{process.env.PAYMENT_CONFIRMATION_MESSAGE}</p>
              <Row className={style.orderInfoSection}>
                <h2 className="mb-3 text-uppercase">Order Information</h2>
                <Row>
                  <Col>
                    <h3>Customer Details</h3>
                    <p>
                      {firstName} {lastName}
                    </p>
                    <p>{email}</p>
                  </Col>
                  <Col>
                    <h3>Order Number</h3>
                    <p>{orderNumber}</p>
                  </Col>
                  <Col>
                    <h3>Order Date</h3>
                    <p>{orderDate}</p>
                  </Col>
                </Row>
              </Row>

              <Row className={style.fullOrdserDetailsSection}>
                <Col md={6}>
                  {deliveryMethod === "clickAndCollect"
                    ? renderCollectionInfo()
                    : renderDeliveryInfo()}

                  <h2 className="mt-5">Payment</h2>
                  <h3>Credit card</h3>
                </Col>

                {/*<Col md={6}> TODO*/}
                {/*  <h2>Products Ordered</h2>*/}
                {/*  {basketItems.map(item => (*/}
                {/*    <OrderSummaryItem key={item.id} product={item} />*/}
                {/*  ))}*/}
                {/*</Col>*/}
              </Row>

              <Row className={style.orderTotalSection}>
                <p>
                  Products subtotal<span className="float-right">£{subtotal}</span>
                </p>
                {bikeAssembly && (
                  <p>
                    Built & ready to ride service
                    <span className="float-right">£100</span>
                  </p>
                )}
                <p>
                  Delivery <span className="float-right">
                  {shippingFeeValue}
                </span>
                </p>
                { couponVal && <p>
                  Discount <span className="float-right">
                    {couponVal}
                  </span>
                </p>}
                {allFees > 0 && <p> FEES
                  <span className="float-right">{process.env.CURRENCY_SYMBOL}{allFees}</span>
                  </p>}
                <p className="mt-4 mb-0 primary-dark border-top pt-3" data-price={total}>
                  Total <span className="float-right h2" data-total={totalValue}>
                    {totalValue}</span>
                </p>
              </Row>

              <Row className="d-flex justify-content-center pt-3 pb-large">
                <Link
                  className="py-3 px-4 text-white text-decoration-none btn-primary"
                  link="/">
                  Back To Homepage
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default PaymentSuccess
