// extracted by mini-css-extract-plugin
export var bgGrey100 = "paymentSuccess-module--bg-grey-100--314ef";
export var bgGrey150 = "paymentSuccess-module--bg-grey-150--06e61";
export var bgGrey200 = "paymentSuccess-module--bg-grey-200--7bdc8";
export var bgGrey300 = "paymentSuccess-module--bg-grey-300--5ef0f";
export var bgGrey400 = "paymentSuccess-module--bg-grey-400--f7b92";
export var bgGrey500 = "paymentSuccess-module--bg-grey-500--8f2a7";
export var bgGrey600 = "paymentSuccess-module--bg-grey-600--14ccc";
export var bgGrey700 = "paymentSuccess-module--bg-grey-700--d60b9";
export var bgGrey800 = "paymentSuccess-module--bg-grey-800--40edd";
export var bgGrey900 = "paymentSuccess-module--bg-grey-900--5cefb";
export var deliveryIcon = "paymentSuccess-module--deliveryIcon--24d0c";
export var fullOrdserDetailsSection = "paymentSuccess-module--fullOrdserDetailsSection--ee12a";
export var orderInfoSection = "paymentSuccess-module--orderInfoSection--8063e";
export var orderTotalSection = "paymentSuccess-module--orderTotalSection--6d2b3";
export var pageBody = "paymentSuccess-module--pageBody--ba8f6";
export var textGrey100 = "paymentSuccess-module--text-grey-100--2582e";
export var textGrey150 = "paymentSuccess-module--text-grey-150--1b03c";
export var textGrey200 = "paymentSuccess-module--text-grey-200--43166";
export var textGrey300 = "paymentSuccess-module--text-grey-300--875f8";
export var textGrey400 = "paymentSuccess-module--text-grey-400--07fb3";
export var textGrey500 = "paymentSuccess-module--text-grey-500--13406";
export var textGrey600 = "paymentSuccess-module--text-grey-600--49ee8";
export var textGrey700 = "paymentSuccess-module--text-grey-700--657fc";
export var textGrey800 = "paymentSuccess-module--text-grey-800--3431b";
export var textGrey900 = "paymentSuccess-module--text-grey-900--c0bd8";